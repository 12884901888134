import { Suspense } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { RecoilRoot } from "recoil";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
    -webkit-font-smoothing: antialiased;
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const Loading = () => <h1>Loading...</h1>;

const App = ({ children }) => {
  return (
    <RecoilRoot>
      <AppContainer>
        <GlobalStyle />
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </AppContainer>
    </RecoilRoot>
  );
};

export default App;
