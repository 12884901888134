import { selector } from "recoil";
// temporarily import copy from local file
import LocalCopy from "../temp-copy.json";
import providerState from "./provider";

const sleep = (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms));

const fetchCopy = async (countryCode, languageCode) => {
  const previewMode = document.cookie.indexOf("sussex-app-preview=1") >= 0;

  await sleep(500);
  if (LocalCopy) {
    return LocalCopy;
  }

  const viewingMode = previewMode ? "draft" : "published";
  const url = `${
    process.env.REACT_APP_COPY_GROUPS_CDN_URL
  }/book-now/psychologytoday/therapists/${viewingMode}/${countryCode.toLowerCase()}/${languageCode.toLowerCase()}.json`;
  try {
    const response = await fetch(url);
    const rawCopy = await response.json();
    const copy = rawCopy.reduce((accum, curr) => {
      accum[curr.key] = curr.value;
      return accum;
    }, {});
    return copy;
  } catch (err) {
    throw new Error("Failed to fetch copy", err);
  }
};

const copy = selector({
  key: "Copy", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
  get: ({ get }) => {
    const { countryCode, languageCode } = get(providerState);
    return fetchCopy(countryCode, languageCode);
  },
});

export default copy;
