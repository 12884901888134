import styled from "styled-components";
import StepForm from "../components/StepForm";
import BackgroundImage from "../components/BackgroundImage";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Account for border radius: */
  padding-bottom: 10px;
  margin-bottom: -10px;
  @media (min-width: 900px) {
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
  }
`;

const FormWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex: 1;
  @media (min-width: 900px) {
    flex: 1 0 auto;
  }
`;

export default function LandingView() {
  return (
    <Wrapper>
      <ContentWrapper>
        <BackgroundWrapper>
          <BackgroundImage />
          <Header />
          <Footer />
        </BackgroundWrapper>
        <FormWrapper>
          <StepForm />
        </FormWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}
